import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import IUniversityResponse from "@models/university";
import IProfile from "@models/profile";
import { createCoreUrl, createProfileUrl } from "@utils/urlFactory";
import { HttpService } from "@core/http";

@Injectable({
  providedIn: "root"
})
export class UniversityService {
  constructor(private client: HttpService<IUniversityResponse>) {}

  public getAllUniversities = (): Observable<IUniversityResponse[]> => {
    return this.client
      .get<IUniversityResponse[]>(createCoreUrl("universities"))
      .pipe(shareReplay(1));
  };

  public getUniversityById = (
    universityId: string
  ): Observable<IUniversityResponse> => {
    return this.client.get<IUniversityResponse>(
      createCoreUrl("universities", universityId)
    );
  };

  public sendFeedback = (
    universityId: string,
    feedback: { description: string; screenshot: string }
  ): Observable<IUniversityResponse> => {
    return this.client.post<IUniversityResponse>(
      createCoreUrl("universities", universityId, "feedback"),
      feedback,
      {
        headers: {
          Authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjViNzZlN2ZlNDUzZDc3MGZlMDE4Y2FkMCIsImlhdCI6MTUzNDUxOTM3OSwiZXhwIjoxNTM0NTIyOTc5fQ.4A2lRZF4MPLAyMoywBkZCCGyCEejAsK-0hV_oPMWFAU"
        }
      }
    );
  };

  public setMainInstitution(id: string) {
    return this.client.post<IProfile>(createProfileUrl("university", id), null);
  }
}
