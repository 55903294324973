import { Component, Input, OnInit, Output } from "@angular/core";
import BasicRxComponent from "@components/BasicRxComponent";
import { IAuthProfile, LoginService } from "@core/auth";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-profile-dropdown",
  template: `
    <div
      class="profile-dropdown"
      [clickOutsideEnabled]="clickOutsideEnabled$.getValue()"
      (clickOutside)="close()"
    >
      <a class="profile-dropdown__toggle" (click)="toggle()">
        <app-avatar
          [url]="(loginService.profileInfo | async).picture"
        ></app-avatar>
      </a>
      <ul
        class="profile-dropdown__content"
        [class.profile-dropdown__content_open]="shown | async"
      >
        <li class="profile-dropdown__item">
          <a class="profile-dropdown__link">
            <div class="profile-dropdown__icon">
              <app-avatar
                [url]="(loginService.profileInfo | async).picture"
              ></app-avatar>
            </div>
            <div class="profile-dropdown__text">
              {{ (loginService.profileInfo | async).name }}
            </div>
          </a>
        </li>
        <li class="profile-dropdown__item">
          <a class="profile-dropdown__link" (click)="loginService.logout()">
            <div class="profile-dropdown__icon">
              <i class="fa fa-sign-out"></i>
            </div>
            <div class="profile-dropdown__text">Logout</div>
          </a>
        </li>
      </ul>
    </div>
  `,
  styleUrls: ["./profile-dropdown.component.scss"]
})
export class ProfileDropdownComponent
  extends BasicRxComponent
  implements OnInit
{
  clickOutsideEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  shown = new BehaviorSubject<boolean>(false);

  constructor(public loginService: LoginService) {
    super();
  }

  toggle() {
    const val = this.shown.getValue();
    this.shown.next(!val);
  }

  close() {
    this.shown.next(false);
  }

  ngOnInit() {
    this.bag.add(
      this.shown.subscribe((shown) => this.clickOutsideEnabled$.next(shown))
    );
  }
}
