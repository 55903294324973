import { BrowserModule } from "@angular/platform-browser";
import { InjectionToken, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LayoutModule } from "@angular/cdk/layout";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoursePreviewComponent } from "@components/coursepreview/coursepreview.component";

import { environment } from "../environments/environment";

import localeFr from "@angular/common/locales/fr";
import { CommonModule, registerLocaleData } from "@angular/common";
import { MarkdownModule } from "ngx-markdown";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { NgxPayPalModule } from "ngx-paypal";
import { EcospinnerModule } from "@components/ecospinner/ecospinner.module";
import { SectionModule } from "@components/section/section.module";
import { TopbarModule } from "./modules/public-access-modules/topbar/topbar.module";
import { FooterModule } from "@components/footer/footer.module";
import { SidebarModule } from "./modules/members-access-modules/sidebar/sidebar.module";
import { BreadcrumbsModule } from "./modules/members-access-modules/breadcrumbs/breadcrumbs.module";
import { routes } from "./app.routes";

import { CoreModule } from "@core/core.module";
import { ModalsModule } from "@components/modals/modals.module";
import { OrdersService } from "@services/orders.service";
import { PriceFormatService } from "@services/price-format.service";
import { ProfileService } from "@services/profile.service";
import { SpaService } from "@services/spa.service";
import { TopbarContextService } from "@services/topbar-navigation.service";
import { TranslationService } from "@services/translation.service";
import { UniversityService } from "@services/university.service";
import { WindowRefService } from "@services/window-ref.service";
import { SessionActivityService } from "@services/session-activity.service";
import { GoogleReviewsService } from "@services/google-reviews/google-reviews.service";
import { RouterService } from "@services/router";

import { SharedModule } from "./shared/shared.module";
import {
  LocalStorageService,
  SessionStorageService
} from "@services/local-storage";
import { BrowserService } from "@services/browser.service";
import { CourseNavigationContext } from "@services/chapter-navigation.service";
import { ChapterService } from "@services/chapter.service";
import { CommentsService } from "@services/comments.service";
import { ContentService } from "@services/content.service";
import { ContextService } from "@services/context.service";
import { PROGRESS_SERVICE, ProgressService } from "@services/progress";
import { EnrollmentService } from "@services/enrollment.service";
import { IngestionService } from "@services/ingestion";
import { LoggerService } from "./shared/services/logger.service";
import { IntroTutorialService } from "@services/intro-tutorial.service";
import { PlatformService } from "@services/platform.service";

registerLocaleData(localeFr, "de");


const externalUrlProvider = new InjectionToken("externalUrlRedirectResolver");

const SERVICES = [
  SessionActivityService,
  BrowserService,
  ChapterService,
  CourseNavigationContext,
  CommentsService,
  ContentService,
  ContextService,
  IntroTutorialService,
  LocalStorageService,
  OrdersService,
  PriceFormatService,
  ProfileService,
  SpaService,
  TopbarContextService,
  TranslationService,
  UniversityService,
  WindowRefService,
  SessionStorageService,
  RouterService,
  GoogleReviewsService,
  EnrollmentService,
  IngestionService,
  {
    provide: PROGRESS_SERVICE,
    useClass: ProgressService
  },
  LoggerService,
  PlatformService
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    ModalsModule.forRoot(),
    SharedModule,
    CoreModule,
    RouterModule.forRoot(routes, {
      enableTracing: false,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "top"
    }),
    RecaptchaV3Module,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // NgxSelectModule,
    MarkdownModule.forRoot(),
    NgxPayPalModule,
    LayoutModule,
    EcospinnerModule,
    SectionModule,
    TopbarModule,
    FooterModule,
    SidebarModule,
    BreadcrumbsModule
  ],
  declarations: [AppComponent, CoursePreviewComponent],
  providers: [
    SERVICES,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaKey },
    {
      provide: externalUrlProvider,
      useValue: () => {
        const externalUrl = environment.logoutUrl;
        window.open(externalUrl, "_self");
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
