import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IntersactionObserverDirective } from "./directives/intersaction-observer/intersaction-observer.directive";


@NgModule({
  declarations: [IntersactionObserverDirective],
  imports: [CommonModule],
  exports: [IntersactionObserverDirective]
})
export class SharedModule {}
