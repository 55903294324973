import { Injectable } from "@angular/core";
import IUniversityResponse from "@models/university";
import { pagesConfig } from "../../modules/public-access-modules/shared/content/pages-content";
import { PlatformService } from "./platform.service";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class TranslationService {
  public coursesTitle$ = new BehaviorSubject<string>(null);

  constructor(private platformService: PlatformService) {
    this.platformService.platform$.subscribe(() => {
      this.setCourseTitle();
    });
  }

  public environmentTitle(): string {
    return this.determine("ecoreps");
  }

  public getPageContent<T>(page: string): T {
    return pagesConfig[this.environmentTitle()][page];
  }

  // Plural of students title
  public studentsTitle(): string {
    return this.determine("Studierenden");
  }

  // Subline in public page courses
  public coursesTitleSubline(institution: IUniversityResponse): string {
    return this.determine(institution ? "An der " + institution.name : "");
  }

  // Contact E-Mail in public page contact
  public contactEmailCoursesDefault(): string {
    return this.determine("orga@ecoreps.de");
  }

  public contactEmail(): string {
    return this.determine("kontakt@ecoreps.de");
  }

  // Contact phone number in public page contact
  public contactPhone(): string {
    return this.determine("0175 335 31 58");
  }
  // Contact phone number URL formatted in public page contact
  public contactPhoneForURL(): string {
    return this.determine("+49 160 913 606 90");
  }

  public orderEmail(): string {
    return this.determine("orders@ecoreps.de");
  }

  // Singular title of institution
  public institutionSingular(): string {
    return this.determine("Universität");
  }

  // determines which translation to use
  private determine(ecoreps: string): string | null {
    return ecoreps;
  }

  private setCourseTitle() {
    if (this.platformService.isMobile()) {
      // For mobile we have specific line break
      this.coursesTitle$.next(
        this.determine("Klausur-<br/>vorbereitungs-<br/>kurse")
      );
      return;
    }
    this.coursesTitle$.next(this.determine("Klausurvorbereitungskurse"));
  }
}
