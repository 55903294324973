import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpModule } from "./http/http.module";
import { AuthModule } from "./auth/auth.module";

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpModule, AuthModule]
})
export class CoreModule { }


