import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  AdminGuardService,
  AnonymouGuardService,
  AuthGuardService,
  OrderGuardService
} from "./auth.guard";
import { LoggedInGuard } from "./loggedIn.guard";
import { LoginService } from "./login.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./auth.interceptor";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    LoginService,
    AuthGuardService,
    AnonymouGuardService,
    OrderGuardService,
    AdminGuardService,
    LoggedInGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ]
})
export class AuthModule {}
