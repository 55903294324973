import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { HealthCheckService } from "./health-check.service";

@Injectable({
  providedIn: "root"
})
export class PreventNavigationGuard  {
  constructor(private healthCheckService: HealthCheckService) {}

  canDeactivate(): Observable<boolean> | boolean {
    return this.healthCheckService.navigationOpen;
  }
}
