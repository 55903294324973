import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

interface IDimensions {
  width: number;
  height: number;
}

export type ExtendedWindow = Window & { dataLayer?: { event: string }[] };

function getWindow(): ExtendedWindow  {
  return window;
}

export interface IWindowRef {
  nativeWindow: ExtendedWindow;
  resize: Observable<IDimensions>;
}

@Injectable({
  providedIn: 'root'
})
export class WindowRefService implements IWindowRef{
  private _resize$: Subject<IDimensions> = new Subject();

  constructor() {
    this.subscribeToWindowResize();
  }

  get nativeWindow(): ExtendedWindow {
    return getWindow();
  }

  get resize(): Observable<IDimensions> {
    return this._resize$.asObservable().pipe(debounceTime(250));
  }

  private subscribeToWindowResize() {
    this.nativeWindow.addEventListener("resize", () => {
      const sizes = {
        height: this.nativeWindow.innerHeight,
        width: this.nativeWindow.innerWidth
      };

      this._resize$.next(sizes);
    });
  }
}
