import { ProgressState } from "./profile";

export type ProgressStateValue =
  | "undefined"
  | "notUnderstood"
  | "partlyUnderstood"
  | "fullyUnderstood";

export const PROGRESS_STATE: Record<ProgressState, ProgressStateValue> = {
  [ProgressState.undefined]: "undefined",
  [ProgressState.notUnderstood]: "notUnderstood",
  [ProgressState.partlyUnderstood]: "partlyUnderstood",
  [ProgressState.fullyUnderstood]: "fullyUnderstood"
};
export interface ContentScope {
  resourceURI: string;
}

export enum INGESTION_EVENT_TYPES {
  ACTIVITY = "activity",
  PROGRESS = "progress"
}

export enum INGESTION_PAYLOAD_TYPE {
  SCROLL = "scroll",
  NAVIGATE = "navigate"
}

export interface IIngestionPayload {
  event_type: INGESTION_EVENT_TYPES;
  clientId: string; // "auth0|5ee62498ca6f4800199507dd"
  targetState?: ProgressStateValue;
  scope: {
  resourceURI: string; // {course._id},{column.id},{chapter.id}, {subchapter.id}?#{section.id?}
  };
  type: INGESTION_PAYLOAD_TYPE;
}
