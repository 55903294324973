import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { IDialog } from "./modal.types";
import { MODAL_IDS } from "./modals.constants";

type Keys = keyof typeof MODAL_IDS;
export type MODAL_ID = (typeof MODAL_IDS)[Keys];
@Injectable({
  providedIn: null
})
export class ModalsService {
  public readonly MODAL_IDS = MODAL_IDS;
  private modals: Map<string, IDialog> = new Map();

  private _onCloseCallbacks = new Map<string, () => void>();
  public onClose$: Subject<string> = new Subject();

  constructor(private router: Router) {
    this.subscribeToNavigationEnd();
  }

  add(modal: IDialog): void {
    // add modal to array of active modals
    this.modals.set(modal.id, modal);
  }

  remove(id: MODAL_ID): void {
    // remove modal from array of active modals
    this.modals.get(id)?.remove();
    this.modals.delete(id);
  }

  open(id: MODAL_ID, onCloseCallback?: () => void): void {
    // open modal specified by id
    if (!this.modals.has(id)) {
      throw `Modal is not exists ${id}`;
    }
    this.unhideRootContainer(this.modals.get(id).rootElement);
    this._onCloseCallbacks.set(id, onCloseCallback);
    this.modals.get(id).open();
  }

  close(id: MODAL_ID): void {
    // close modal specified by id
    if (!this.modals.has(id)) {
      return;
    }
    const onClose = this._onCloseCallbacks.get(id);
    this.hideRootContainer(this.modals.get(id).rootElement);
    if (onClose) {
      onClose();
    }
    this.onClose$.next(id);
    this._onCloseCallbacks.delete(id);
  }

  public isModalAlreadyExists(modalId) {
    return this.modals.has(modalId);
  }

  private closeAll() {
    for (const [_id, modal] of this.modals) {
      modal.close();
    }
  }

  private subscribeToNavigationEnd() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.closeAll();
      });
  }

  private unhideRootContainer(container: HTMLDivElement) {
    container.parentElement.classList.add("visible");
  }

  private hideRootContainer(container: HTMLDivElement) {
    container.parentElement.classList.remove("visible");
  }
}
