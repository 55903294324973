import { PreventNavigationGuard } from "@core/http/prevent-navigation.guard";
import {
  AdminGuardService,
  AnonymouGuardService,
  AuthGuardService
} from "./modules/core/auth/auth.guard";
import { LoggedInGuard } from "./modules/core/auth/loggedIn.guard";
import { Routes } from "@angular/router";

const memberRoutes: Routes = [
  {
    path: "order/:courseId",
    loadChildren: () =>
      import(
        "./modules/members-access-modules/course/order-course/order-course.module"
      ).then((m) => m.OrderCourseModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "courses",
    loadChildren: () =>
      import(
        "./modules/members-access-modules/course/course-component/course.module"
      ).then((m) => m.CourseModule),
    canActivate: [AnonymouGuardService]
  },
  {
    path: "mycourses",
    loadChildren: () =>
      import(
        "./modules/members-access-modules/mycourses/mycourses.module"
      ).then((m) => m.MyCoursesModule),
    canActivate: [AnonymouGuardService]
  }
];

const ecorepsRoutes: Routes = [
  {
    path: "about-us",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/aboutus/aboutus.module"
      ).then((m) => m.AboutusModule),
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "offers",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/shared/pages/offer/offer.module"
      ).then((m) => m.OfferModule),
    canActivate: [AnonymouGuardService],
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "become-a-tutor",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/become-a-tutor/become-a-tutor.module"
      ).then((m) => m.BecomeATutorModule)
  },
  {
    path: "contact",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/shared/pages/contact/contact.module"
      ).then((m) => m.ContactModule),
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "institution/course/:course",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/shared/pages/course/course.module"
      ).then((m) => m.CourseModule),
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "imprint",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/imprint/imprint.module"
      ).then((m) => m.ImprintModule),
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/privacy-policy/privacy-policy.module"
      ).then((m) => m.PrivacyPolicyModule),
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "not-authorized",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/not-authorized/not-authorized.module"
      ).then((m) => m.NotAuthorizedModule),
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "not-verified",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/not-verified/not-verified.module"
      ).then((m) => m.NotVerifiedModule),
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "verify",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/verify/verify.module"
      ).then((m) => m.VerifyModule),
    canDeactivate: [PreventNavigationGuard]
  },
  // {
  //   path: "not-found",
  //   loadChildren: () =>
  //     import(
  //       "./modules/public-access-modules/ecoreps/additional-routes/not-found/not-found.module"
  //     ).then((m) => m.NotFoundModule),
  //   canDeactivate: [PreventNavigationGuard]
  // },
  {
    path: "server-error",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/server-error/server-error.module"
      ).then((m) => m.ServerErrorModule),
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "health-check-error",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/internal-error/internal-error.module"
      ).then((m) => m.InternalErrorModule),
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "bad-gateway",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/bad-gateway-error/bad-gateway-error.module"
      ).then((m) => m.BadGatewayErrorModule)
  },
  {
    path: "forbidden",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/additional-routes/forbidden/forbidden.module"
      ).then((m) => m.ForbiddenModule),
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "home/:action",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/landingpage/landingpage.module"
      ).then((m) => m.LandingpageModule),
    canActivate: [LoggedInGuard],
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "institution/:institution",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/shared/pages/courses/courses.module"
      ).then((m) => m.CoursesModule),
    canDeactivate: [PreventNavigationGuard]
  },
  {
    path: "**",
    redirectTo: "not-found"
  }
];

const adminRoutes: Routes = [
  {
    path: "admin",
    loadChildren: () =>
      import("./modules/admin-access-modules/admin.module").then(
        (m) => m.AdminModule
      ),
    canActivate: [AdminGuardService]
  }
];

const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "home",
    loadChildren: () =>
      import(
        "./modules/public-access-modules/ecoreps/landingpage/landingpage.module"
      ).then((m) => m.LandingpageModule),
    canDeactivate: [PreventNavigationGuard]
  }
];

export const routes: Routes = [
  ...appRoutes,
  ...memberRoutes,
  ...adminRoutes,
  ...ecorepsRoutes
];
