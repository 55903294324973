import { Injectable } from "@angular/core";
import { HttpService } from "@core/http";
import {
  IAverageRatingResponse,
  IGoogleReview,
  IGoogleReviewsResponse
} from "@models/review";
import { convertQueryParamsToString, createCoreUrl } from "@utils/urlFactory";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

interface IGoogleReviewsQueryParams {
  university: string;
  published?: boolean
}

@Injectable({
  providedIn: "root"
})
export class GoogleReviewsService {
  constructor(private cachedClient: HttpService<any>) {}

  getAll(params: IGoogleReviewsQueryParams) {
    const queryParams =
      convertQueryParamsToString<IGoogleReviewsQueryParams>({...params, published: true});
    const url = createCoreUrl(`reviews?${queryParams}`);
    if (this.cachedClient.getCachedEntity(url)) {
      return of(this.cachedClient.getCachedEntity(url));
    }
    return this.cachedClient.get<IGoogleReviewsResponse>(url).pipe(
      catchError(this.handleError),
      map((result: IGoogleReviewsResponse) => {
        this.cachedClient.addToCache(result.items, url);
        return result.items;
      })
    );
  }

  getPopular(): Observable<IGoogleReview[]> {
    const url = createCoreUrl("reviews?type=popular");

    if (this.cachedClient.getCachedEntity(url)) {
      return of(this.cachedClient.getCachedEntity(url));
    }
    return this.cachedClient.get<IGoogleReviewsResponse>(url).pipe(
      catchError(this.handleError),
      map((result: IGoogleReviewsResponse | null) => {
        if (result) {
          this.cachedClient.addToCache(result.items, url);
          return result.items;
        }
        return [];
      })
    );
  }

  getAverageRating(): Observable<IAverageRatingResponse> {
    const url = createCoreUrl("reviews/rating");
    if (this.cachedClient.getCachedEntity(url)) {
      return of(this.cachedClient.getCachedEntity(url));
    }
    return this.cachedClient
      .get<IAverageRatingResponse>(createCoreUrl("reviews/rating"))
      .pipe(
        map((entity) => {
          this.cachedClient.addToCache(entity, url);
          return entity;
        })
      );
  }

  private handleError(e: Error) {
    console.error(e);
    return of(null);
  }
}
