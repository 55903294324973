import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router, RouterEvent } from "@angular/router";
import { LoginService } from "@core/auth";
import { WindowRefService } from "@services/window-ref.service";
import { BehaviorSubject, Observable, of, race, throwError } from "rxjs";
import {catchError, map, share, filter, tap, finalize, shareReplay} from "rxjs/operators";

import {
  RequestOption,
  ERROR_REASON,
  getReason,
  ERROR_STATUSES
} from "./http.utils";

const DEFAULT_REQUEST_TIMEOUT_MS = 60000;

/**
 * This is a base http client for all services, wraps httpClient
 */
@Injectable()
export class HttpService<C> {
  private _requestTimeout = DEFAULT_REQUEST_TIMEOUT_MS;
  protected _requestsInProgress = new Map<string, Observable<any>>();
  protected _failedRequestsToRetry = new Map<string, Observable<any>>();
  protected _cachedEntities = new Map<string, C>();

  protected currentUrl = new BehaviorSubject<string | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private auth: LoginService,
    private windowRefService: WindowRefService
  ) {
    this.router.events
      .pipe(
        map((e: any): string => e.url),
        filter(Boolean)
      )
      .subscribe((url: string) => {
        this.currentUrl.next(url);
      });
  }
  /**
   * Setter only for UNIT TESTS purpose DON'T USE IT IN CODE
   */
  public set __REQUEST_TIMEOUT__(value: number) {
    this._requestTimeout = value;
  }

  public get requestsQueue(): Map<string, Observable<any>> {
    return this._requestsInProgress;
  }

  public get failedRequests(): Map<string, Observable<any>> {
    return this._failedRequestsToRetry;
  }

  public get<T>(
    url: string,
    options?: RequestOption,
    customErrorHandler?: (error: HttpErrorResponse) => boolean
  ): Observable<T> {
    if (this._requestsInProgress.has(url)) {
      return this._requestsInProgress.get(url);
    }
    const response = this.request<T>("GET", url, options).pipe(
      this.commonRequestPipes(customErrorHandler),
      tap(() => this._requestsInProgress.delete(url)),
    );
    this._requestsInProgress.set(url, response);
    return response;
  }

  public post<T>(
    url: string,
    body: object,
    options?: RequestOption,
    customErrorHandlerGetter?: (error: HttpErrorResponse) => boolean
  ): Observable<T> {
    const response = this.request<T>("POST", url, { ...options, body }).pipe(
      this.commonRequestPipes(customErrorHandlerGetter)
    );

    return response;
  }

  public put<T>(
    url: string,
    body: object,
    options?: RequestOption,
    customErrorHandler?: (error: HttpErrorResponse) => boolean
  ): Observable<T> {
    const response = this.request<T>("PUT", url, { ...options, body }).pipe(
      this.commonRequestPipes(customErrorHandler)
    );

    return response;
  }

  public delete<T>(
    url: string,
    options?: RequestOption,
    customErrorHandler?: (error: HttpErrorResponse) => boolean
  ): Observable<T> {
    const response = this.request<T>("DELETE", url, options).pipe(
      this.commonRequestPipes(customErrorHandler)
    );

    return response;
  }

  public addToCache(
    entity: C & { url_slug?: string; _id: string },
    customKey?: string
  ): void {
    const key = customKey || entity.url_slug || entity._id;
    this._cachedEntities.set(key, entity);
  }

  public clearCache(): void {
    this._cachedEntities.clear();
  }

  public getCachedEntity(id: string): C | null {
    return this._cachedEntities.get(id);
  }

  private request<T>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    url: string,
    options: RequestOption<any>
  ) {
    // Need to uncomment after fix ingestion requests error
    // const timerObservable = timer(this._requestTimeout).subscribe(() => {
    //   this.handleBadGateway();
    // });

    const requestObservable = this.http.request<T>(method, url, options).pipe(
      share(),
      // map((data) => {
      //   timerObservable.unsubscribe();
      //   return data;
      // }),
    );

    return race(requestObservable);
  }

  private handleHttpError(
    error: HttpErrorResponse,
    customErrorHandler: (error: HttpErrorResponse) => boolean
  ): Observable<any> {
    const reason = getReason(error);

    if (customErrorHandler && customErrorHandler(error)) {
      return throwError(error);
    }
    switch (error.status) {
      case ERROR_STATUSES.BAD_REQUEST:
        return this.handleBadRequest(error, reason);
      case ERROR_STATUSES.NOT_AUTHORISED:
        return this.handleNotAuthorized();
      case ERROR_STATUSES.NOT_FOUND:
        return this.handleNotFound();
      case ERROR_STATUSES.FORBIDDEN:
        return this.handleForbidden();
      case ERROR_STATUSES.ENROLMENT_NOT_FOUND:
        return throwError(error);
      case ERROR_STATUSES.BAD_GATEWAY:
        return this.handleBadGateway();
      case ERROR_STATUSES.SERVER_ERROR:
        return this.handleServerError(error);
      default:
        return throwError(error);
    }
  }

  private handleBadRequest(
    error: HttpErrorResponse,
    reason: ERROR_REASON | string | undefined
  ): Observable<null | never> {
    if (reason === ERROR_REASON.VERSIONS_MISMATCH) {
      this.windowRefService.nativeWindow.location.reload();
      return of(null);
    }

    if (
      reason === ERROR_REASON.MISSING_UNIVERSITY &&
      this.currentUrl.getValue() === "/mycourses"
    ) {
      if (this.auth.isAnonymous.getValue()) {
        return of(null);
      }

      this.router.navigate(["mycourses"]);
      return of(null);
    }

    return throwError(error);
  }

  private handleForbidden() {
    this.router.navigate(["home"]);
    return of(null);
  }

  private handleNotFound() {
    this.router.navigate(["not-found"]);
    return of(null);
  }

  private handleServerError(e: HttpErrorResponse) {
    this.router.navigate(["server-error"]);
    return throwError(e);
  }

  private handleNotAuthorized() {
    this.auth.logout();
    this.router.navigate(["home"]);
    return of(null);
  }

  private handleBadGateway(): any {
    this.router.navigate(["bad-gateway"]);
    return of(null);
  }

  private commonRequestPipes<T>(
    customErrorHandler?: (error: HttpErrorResponse) => boolean
  ): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) =>
      source.pipe(
        share(),
        catchError((e: HttpErrorResponse) =>
          this.handleHttpError(e, customErrorHandler)
        )
      );
  }
}
