<div id="page-container" [class]="(cssClass | async)">
  <topbar *ngIf="(topBar | async).show"></topbar>
  <div class="app-content" [class.with-sidebar]="(sideBar | async).show">
    <sidebar *ngIf="(sideBar | async).show"></sidebar>
    <router-outlet></router-outlet>
  </div>
  <footer-component *ngIf="(topBar | async).show"></footer-component>
  <div id="modalContainer" class="modal-container"></div>
</div>

<div [class]="dontwaste"></div>
