import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";

import { environment } from "@environments/environment";
import {
  ChapterPriority,
  IChapter,
  ICreateChapterModel,
  IMoveChapterModel,
  IUpdateChapterModel,
  PreviewMode
} from "@models/chapter";
import { ICourse } from "@models/course";
import { LoginService } from "@core/auth/login.service";
import { map, share } from "rxjs/operators";
import { applyHierarchical, ChapterIndex } from "@utils/chapterUtils";
import IUniversityResponse from "@models/university";
import ISection from "@models/section";
import {
  ICompletionRequest,
  IProgressChapterEntry,
  ProgressState
} from "@models/profile";
import { HttpService } from "@core/http";
import {createContentUrl, createCoreUrl, createProfileUrl} from "@utils/urlFactory";
import {ILatexErrorResponse} from "@models/content/latex-request";

@Injectable({
  providedIn: "root"
})
export class ChapterService {
  constructor(
    private client: HttpService<IChapter>,
    private loginService: LoginService
  ) {}

  public isChapterForbidden(chapter: IChapter, course: ICourse): boolean {
    if (course.isPurchased) {
      return false;
    }

    const allowedModes = [PreviewMode.publicMode];

    if (!this.loginService.isAnonymous.getValue()) {
      allowedModes.push(PreviewMode.membersMode);
    }
    return !(chapter.inPreview && allowedModes.includes(chapter.previewMode));
  }

  public isChapterInMembersPreviewOnly(
    chapter: IChapter,
    course: ICourse
  ): boolean {
    if (course.isPurchased) {
      return false;
    }

    return chapter.inPreview && chapter.previewMode == PreviewMode.membersMode;
  }

  public getChapterById = (
    chapterId: string,
    forceReload: boolean = false,
    isCustomErroHandling?: (error: HttpErrorResponse) => boolean
  ): Observable<IChapter> => {
    const cachedChapter = this.client.getCachedEntity(chapterId);

    if (cachedChapter && !forceReload) {
      return of(cachedChapter);
    } else {
      const url = createCoreUrl("chapters", chapterId);

      return this.client
        .get<IChapter>(url, undefined, isCustomErroHandling)
        .pipe(
          map((chapter) => {
            chapter.index = new ChapterIndex(
              chapter.orderString.split(",").map((i) => parseInt(i))
            );

            applyHierarchical(chapter.subchapters, (_, chap) => {
              chap.index = new ChapterIndex(
                chap.orderString.split(",").map((i) => parseInt(i))
              );
            });
            return chapter;
          }),
          map((chapter) => {
            this.client.addToCache(chapter);
            return chapter;
          }),
          share()
        );
    }
  };

  public addChapter(
    chapterCreationModel: ICreateChapterModel,
    course: ICourse
  ): Observable<IChapter> {
    const url = createCoreUrl("courses", course._id, "chapters");
    return this.client.post<IChapter>(url, chapterCreationModel);
  }

  public addChapterSection = (
    section: ISection,
    courseId: string,
    chapterId: string
  ): Observable<IChapter> => {
    const url = createContentUrl(
      "courses",
      courseId,
      "chapters",
      chapterId,
      "sections"
    );

    return this.client.post<IChapter>(url, section);
  };

  public updateChapterSection = (
    section: ISection,
    courseSlug: string,
    chapterSlug: string
  ): Observable<IChapter> => {
    return this.client.put<IChapter>(
      createContentUrl("courses", courseSlug, "chapters", chapterSlug, "sections"),
      section
    );
  };

  public deleteChapterSection = (
    sectionId: string,
    courseId: string,
    chapterId: string
  ): Observable<IChapter> => {
    return this.client.delete<IChapter>(
      createContentUrl(
        "courses",
        courseId,
        "chapters",
        chapterId,
        "sections",
        sectionId
      )
    );
  };

  public duplicateChapter(
    chapter: IChapter,
    courseSlug: string
  ): Observable<IChapter> {
    const body = {
      _id: chapter._id
    };

    return this.client.post<IChapter>(
      createCoreUrl("courses", courseSlug, "chapters", "duplicateChapter"),
      body
    );
  }

  public deleteChapter(chapter: IChapter, courseSlug: string): Observable<any> {
    return this.client.delete<boolean>(
      createCoreUrl("courses", courseSlug, "chapters", chapter._id)
    );
  }

  public updateChapter(chapter: IChapter, courseId: string) {
    const body: IUpdateChapterModel = {
      _id: chapter._id,
      title: chapter.title,
      displayMode: chapter.displayMode,
      priority: chapter.priority || ChapterPriority.normal,
      previewMode: chapter.previewMode
    };
    return this.client.put(
      createCoreUrl("courses", courseId, "chapters"),
      body
    );
  }

  public moveChapter(
    courseId: string,
    moveModel: IMoveChapterModel
  ): Observable<ICourse> {
    const body = moveModel;
    const chap = body.chapterSlug;

    delete body.chapterSlug;

    return this.client.post<ICourse>(
      createCoreUrl("courses", courseId, "chapters", chap, "move"),
      body
    );
  }

  public completeChapter = (
    state: ProgressState,
    course: ICourse,
    chapterId: string,
    sectionId?: string
  ): Observable<IProgressChapterEntry> => {
    const req: ICompletionRequest = {
      sectionId,
      chapterId,
      state
    };
    const url = createProfileUrl("progress", course.url_slug);

    return this.client.post<IProgressChapterEntry>(url, req);
  };

  public exportChapterToLatex = (chapter: IChapter, courseId: string): Observable<Blob> => {
    return this.client.post(
      createContentUrl("courses", courseId, "chapters", chapter._id, "export"),
      {},
      {
        responseType: "blob" as "json",
      }
    );
  }

  public importSection = (sectionId: string, courseId: string, chapterId: string): Observable<IChapter> => {
    return this.client.post(
      createContentUrl("courses", courseId, "chapters", chapterId, "sections", sectionId, "import"),
      {},
    );
  }

  public importChapter = (chapterToImportId: string, courseId: string, chapterId: string): Observable<IChapter> => {
    return this.client.post(
      createContentUrl("courses", courseId, "chapters", chapterId, "import", chapterToImportId),
      {},
    );
  }

  public importChapterFromLatex = (chapter: IChapter, courseId: string, latex: string): Observable<{chapter: IChapter, errors: ILatexErrorResponse[]}> => {
    return this.client.post(
      createContentUrl("courses", courseId, "chapters", chapter._id, "import"),
      { latex },
    );
  }
}
