import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { HttpService } from "@core/http";
import {
  ICourse,
  ICourseEnrolmentInput,
  ICourseEnrolmentResponse
} from "@models/course";
import { createProfileUrl } from "@utils/urlFactory";
import { IRemoveEnrollmentResponse } from "@models/enrollment";


@Injectable({
  providedIn: "root"
})
export class EnrollmentService {
  public enrollmentExists: BehaviorSubject<boolean | undefined> =
    new BehaviorSubject(undefined);

  constructor(private client: HttpService<ICourse>) {}

  public createExamEnrollment(
    body: ICourseEnrolmentInput
  ): Observable<ICourseEnrolmentResponse | undefined> {
    /**
     * To prevent extra call if enrolment already exists
     */
    if (this.enrollmentExists.getValue()) {
      return of();
    }
    return this.client
      .post<ICourseEnrolmentResponse>(createProfileUrl("enrollment"), body)
      .pipe(
        map((result: ICourseEnrolmentResponse) => {
          this.enrollmentExists.next(true);
          return result;
        })
      );
  }

  public removeEnrollment(courseId: string, userId: string): Observable<IRemoveEnrollmentResponse> {
    return this.client.delete<IRemoveEnrollmentResponse>(
      createProfileUrl("enrollment", courseId, userId),
    );
  }

  public setEnrolmentExists(exists: boolean) {
    this.enrollmentExists.next(exists)
  }
}
