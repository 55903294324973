/**
 * Created by huck on 24.06.18.
 */
import { IChapter, ProgressType } from "./chapter";
import IUniversityResponse from "./university";
import { PublishState } from "./publish-state";
import { CourseType } from "./course-type";
import { IEnrollment } from "./enrollment";
import { ICMSImage } from "./cmsImage";

export interface IExamDate {
  start: Date;
  title: string;
  color: string;
}

export enum ImageStyle {
  embedded = 0,
  boxed = 1
}

export interface IColumn {
  title: string;
  id: string;
  _id: string;
  url_slug: string;
  chapter_style: string;
  progress_tracking: ProgressType;
  show_chapter_title: boolean;
  enforce_hierarchical_chapter_structure: boolean;
  show_chapter_indices: boolean;
  allow_comments?: boolean;
  chapters: IChapter[];
  stats: {
    progressAverageUnderstood: number;
    progressAverageProcessed: number;
    chapterCount: number;
    sectionCount: number;
  };
  lastVisited?: {
    title: string;
    url_slug: string;
  };
  image_style: ImageStyle;
}

export enum Currency {
  Euro = "Euro",
  CHF = "CHF"
}

export interface IPrice {
  price: number;
  discount_everyone: number;
  minimum_price: number;
  currency: Currency;
}
// TODO: REFACOR move to utils
export function calculateFinalPrice(price: IPrice): number {
  let result = price.price;
  result -= price.discount_everyone || 0;
  return Math.max(result, price.minimum_price);
}

export interface IPaymentReceiver {
  account_holder: string;
  paypal_account: string;
  info?: string;
  bic: string;
  iban: string;
  currency: string;
}

export interface IProductCategory {
  id: string;
  title: string;
  payment_receiver?: IPaymentReceiver;
  additional_payment_receiver?: IPaymentReceiver;
}

export interface ICourse {
  _id: string;
  id?: string;
  title: string;
  status: PublishState;
  hasPreview?: boolean;
  isPreview?: boolean;
  coverURL: string;
  university?: IUniversityResponse;
  isPurchased?: boolean;
  url_slug?: string;
  contact_phone?: string;
  contact_mail?: string;
  price: IPrice;
  totalProgress?: number;
  processedProgress?: number;
  product_category: IProductCategory;
  columns: IColumn[];
  exams: IExamDate[];
  exam_date?: string;
  enrollment: IEnrollment;
  type: CourseType;
  whatsapp_service: string;
  mycourses_image?: string;
  active?: boolean;
  allow_self_reactivation?: boolean;
  orderable?: boolean;
  userData: {
    allow_self_reactivation?: boolean;
    hasRejectedSelfReactivationRequest?: boolean;
    isAllowSelfReactivationForUser?: boolean;
  };
  stats: {
    chapterCount: number;
    sectionCount: number;
  };
}

export interface IExtandedCourse extends ICourse {
  isPurchased?: boolean;
}

export interface IUpdateCourseModel {
  _id: string;
  title: string;
  contact_mail: string;
  contact_phone: string;
  coverURL: string;
}

export interface ICourseEnrolmentInput {
  course_id: string;
  enrolled: boolean;
  exam_date: string;
  user_id: string;
}

export interface ICourseEnrolmentResponse {
  course_id: string;
  createdAt: string;
  enrolled: boolean;
  exam_date: string;
  is_active: boolean;
  updatedAt: string;
  user_id: string;
  _id: string;
}

interface ITeaserBullet {
  id: number;
  icon: string;
  text: string;
  detail: string;
  order: number;
  hide_detail: boolean;
}

interface ITeaser {
  title: string;
  teaser_bullets: ITeaserBullet[];
}

export interface IReview {
  university: IUniversityResponse;
  author: string;
  text: string;
  time: any;
  courses: ICourse[];
  coursesString: string;
}

export interface IDashboardItemPreviewImage {
  id: string;
  title: string;
  type: "image/png" | "image/jpg";
  width: number;
  height: number;
  description: string|null;
  location: string|null;
  tags: string|null;
  metadata: {};
}
export interface IThumbnailsEntity {
  key: string;
  url: string;
  relative_url: string;
  dimension: string;
  width: number;
  height: number;
}

export interface IDashboardItem {
  id: number;
  status: string;
  owner: number;
  created_on: string;
  order: number;
  title: string;
  body: string;
  video_url: string;
  course: number;
  preview_image: ICMSImage
}

export interface ICourseDetails extends ICourse {
  groups: any[];
  at_one_glance_text: string;
  package_contents_teaser: ITeaser;
  teaser: ITeaser;
  json_appointments: any;
  isOnline: boolean;
  script_contents_text: string;
  calendar_photo: any;
  reviews: IReview[];
  hide_steps_box: boolean;
  dashboard: IDashboardItem[];
}
