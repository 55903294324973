import { environment } from "@environments/environment";

export const convertQueryParamsToString = <T>(queryParams: T): string => {
  return Object.keys(queryParams).reduce((str, key, i) => {
    if (i === 0) {
      return `${key}=${queryParams[key]}`;
    }
    return `${str}&${key}=${queryParams[key]}`;
  }, "");
};

export const createCoreUrl = (...segments: string[]): string => {
  return [environment.API_URL, "core", ...segments].join("/");
};

export const createProfileUrl = (...segments: string[]): string => {
  return [environment.API_URL_PROFILE, "profile", ...segments].join("/");
};

export const createIngestionUrl = (...segments: string[]): string | null => {
  if (environment.API_INGESTION) {
    return [environment.API_INGESTION, "ingestion", ...segments].join("/");
  }
  return null;
};

export const createNotificationUrl = (...segments: string[]): string => {
  return [environment.API_NOTIFICATION, "notification", "public", ...segments].join("/");
};

export const createContentUrl = (...segments: string[]): string => {
  // This is valid because the content service has the same API as the core service (apart from Latex saving/rendering)
  if (environment.features.contentService){
    return [environment.API_CONTENT, "content", ...segments].join("/")
  } else {
    return createCoreUrl(...segments);
  }
}
