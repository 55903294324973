import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ProfileService } from "./profile.service";
import { CourseService } from "./course.service";
import { filter, shareReplay } from "rxjs/operators";
import { ICourse, IColumn, IExtandedCourse } from "@models/course";
import {
  CourseNavigationContext,
  CourseRoute,
  PageType
} from "./chapter-navigation.service";
import { IChapter } from "@models/chapter";
import { LoginService } from "@core/auth/login.service";
import { Router } from "@angular/router";
import IProfile from "@models/profile";
import { PROGRESS_SERVICE, IProgressService } from "./progress";

@Injectable({
  providedIn: "root"
})
export class SpaService {
  public course = new BehaviorSubject<ICourse>(null);
  public column = new BehaviorSubject<IColumn>(null);
  public chapter = new BehaviorSubject<IChapter>(null);
  public pageType = new BehaviorSubject<PageType>(null);

  constructor(
    private profileService: ProfileService,
    private courseService: CourseService,
    private navContext: CourseNavigationContext,
    private loginService: LoginService,
    private router: Router,
    @Inject(PROGRESS_SERVICE) private progressService: IProgressService,
  ) {
    this.onInit();
  }

  public addCourseInfo(course: ICourse): IExtandedCourse | undefined {
    if (!course) {
      return;
    }

    const profile = this.profile.getValue();
    const authProfile = this.loginService.profileInfo.getValue();

    if (profile && authProfile) {
      course.isPurchased = this.getPurchased(course);

      if (profile.progress !== undefined) {
        profile.progress.forEach((progress) => {
          if (progress.course_id === course._id) {
            course.totalProgress = progress.progressTotal;
            course.processedProgress = progress.progressProcessedTotal;
          }
        });
      }
    }
    return course;
  }

  private getPurchased(course: ICourse): boolean {

    return this.getIsPurchased(course._id) || this.loginService.currentIsAdmin;
  }

  private getIsPurchased(courseId: string) {
    return (
      this.getPurchasedFromProfile(courseId) &&
      this.getPurchasedFromAuth(courseId)
    );
  }

  private getPurchasedFromProfile(courseId: string): boolean {
    const profile = this.profile.getValue();
    const isPurchased =
      profile.purchasedCourses && profile.purchasedCourses.includes(courseId);
    return isPurchased;
  }

  private getPurchasedFromAuth(courseId: string): boolean {
    return this.loginService.purchasedCourses.includes(courseId);
  }

  public updateProgress(): void {
    const course = this.course.getValue();
    if (course) {
      this.progressService.progressSummary(course).subscribe((res) => {
        if (course) {
          course.processedProgress = res.progressProcessed;
          course.totalProgress = res.progressTotal;
          this.course.next(course);
        }
      });
    }
  }

  public getFirstChapterWithContent(column: IColumn): IChapter | null {
    const res = this.getFirstChapterWithContentRecursive(
      column.chapters as any
    );
    const first = column.chapters[0] || null;
    return res || (first as any); // TODO: fix types after ng14 migration
  }

  get profile(): BehaviorSubject<IProfile> {
    return this.profileService.profile;
  }

  get profileNotNull(): Observable<IProfile> {
    return this.profileService.profile.pipe(
      filter((p) => !!p),
      shareReplay(1)
    );
  }

  protected getFirstChapterWithContentRecursive(
    chapters: IChapter[],
    res = null
  ): IChapter | null {
    chapters.forEach((chapter) => {
      if (res) return;
      if (chapter.hasSections) {
        res = chapter;
      } else if (chapter.subchapters.length) {
        res = this.getFirstChapterWithContentRecursive(
          chapter.subchapters,
          res
        );
      }
    });

    return res;
  }

  public async orderCourse(course: ICourse): Promise<void> {
    if (this.loginService.loggedInValue) {
      if (course.isPurchased) {
        this.router.navigateByUrl("/courses/" + course.url_slug);
      } else {
        if (this.loginService.isAnonymous.getValue()) {
          this.loginService.loginBookingCourse(course.url_slug, true);
        } else {
          this.router.navigateByUrl("/order/" + course.url_slug);
        }
      }
    } else {
      if (this.loginService.isAnonymous.getValue()) {
        this.loginService.loginBookingCourse(course.url_slug, true);
      } else {
        await this.loginService.getAnonymous();
        this.loginService.loginBookingCourse(course.url_slug, true);
      }
    }
  }

  public onInit(): void {
    this.navContext.route.subscribe((route) => {
      this.setCourse(route.course);
      this.setColumn(route);
      this.setChapter(route);
      this.setPageType(route);
    });

    this.profileNotNull.subscribe(() => {
      this.setCourse(this.course.getValue());
    });

    this.loginService.isAdmin.subscribe((isAdmin) => {
      if (isAdmin) {
        this.setCourse(this.course.getValue());
      }
    });

    this.loginService.profileInfo.subscribe(() => {
      this.profileService.me();
    });
  }

  private setChapter(route: CourseRoute): void {
    this.chapter.next(route.chapter);
  }

  private setCourse(course: ICourse): void {
    this.course.next(this.addCourseInfo(course));
  }

  private setColumn(route: CourseRoute): void {
    this.column.next(
      Array.isArray(route.column) ? route.column[0] : route.column
    );
  }

  private setPageType(route: CourseRoute): void {
    this.pageType.next(route.pageType);
  }
}
