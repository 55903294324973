import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { ContextService } from "@services/context.service";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { LoginService } from "@modules/core/auth/login.service";
import { environment } from "@environments/environment";
import { IGlobalUIContext } from "@models/globalUI.context";
import { TopbarContextService } from "@services/topbar-navigation.service";
import { SidebarService } from "@modules/members-access-modules/sidebar/sidebar.service";
import { SessionActivityService } from "@services/session-activity.service";

import { debounceTime, map, mergeMap, filter } from "rxjs/operators";
import { RouterService } from "@services/router";
import { HealthCheckService } from "@core/http";
import { PlatformService } from "@services/platform.service";

const defaultClassName = "app-container offline-page ecoreps-app-root";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public context: Observable<IGlobalUIContext>;
  public landingPageUrl: string = environment.landingPageUrl;
  public environment = environment;

  public get dontwaste(): string {
    return this.loginService.currentIsAdmin ? "" : "dontwaste";
  }

  public cssClass: BehaviorSubject<string> = new BehaviorSubject<string>(
    defaultClassName
  );

  constructor(
    private contextService: ContextService,
    private topbarContextService: TopbarContextService,
    private sidebarContextService: SidebarService,
    private loginService: LoginService,
    private sessionActivityService: SessionActivityService,
    private routerService: RouterService,
    private healthCheckService: HealthCheckService,
    private platformService: PlatformService
  ) {}

  public register(): void {
    this.loginService.login(null, null, true);
  }

  ngOnInit(): void {
    this.sessionActivityService.onInit();
    this.healthCheckService.onInit();
    this.platformService.ngOnInit();
    this.topbarContextService.next({
      show: false,
      logo: "",
      showFreeTrial: false
    });

    this.routerService.init();

    jQuery(function () {
      setTimeout(function () {
        // and here we add the onscroll listener. Yep, kill me pls
        jQuery(".math_block").on("scroll", function (e) {
          // so, we will add a cheat class, that doesn't really change the element, but forces the background to redraw. Afterwards we remove the class a-sync again, so it actually does redraw and it works more than once. Chrome needs a css change in order to redraw
          const el = jQuery(e.target);
          el.toggleClass("scroll-shadow-cheat-kill-me-later-for-this");
        });
      }, 3000); // just fucking kill me
    });

    // todo: Move code above to this global function and add the onscroll listener in the html onscroll="math_block_scroll_cheat(this)". Like this we can remove that stupid timeout
    (<any>window).math_block_scroll_cheat = function () {};
  }

  ngOnDestroy() {
    this.sessionActivityService.onDestroy();
  }

  ngAfterViewInit() {
    this.contextService.context
      .pipe(
        map((context: IGlobalUIContext) => {
          return `${context.pageClass}`;
        }),
        filter((v) => !!v), // We don't want to take '' page class
        debounceTime(0) // Debounced because of extra messages from context services while application is initilising, 0 because with this value it will be exacuted at next event loop task
      )
      .subscribe((cssClass) => {
        this.cssClass.next([defaultClassName, cssClass].join(" "));
      });
  }

  get isAdminTopBarVisible(): Observable<boolean> {
    return combineLatest([
      this.topbarContextService.context,
      this.sidebarContextService.context,
      this.loginService.isAdmin
    ]).pipe(
      mergeMap((result) => {
        const [topBarContext, sidebarContext, isAdmin] = result;
        return of(!(topBarContext.show || sidebarContext.show) && isAdmin);
      })
    );
  }

  get topBar() {
    return this.topbarContextService.context;
  }

  get sideBar() {
    return this.sidebarContextService.context;
  }
}
