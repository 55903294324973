import { landingPageContent as ecorepsLandingPageContent } from "./ecoreps/landing-page-content";
import { offersPageContent as ecorepsOffersPageContent } from "./ecoreps/offer-page-content";
import { footerContent as ecorepsFooterContent } from "./ecoreps/footer";

export const pagesConfig = {
  ecoreps: {
    landingPage: ecorepsLandingPageContent,
    offerPage: ecorepsOffersPageContent,
    footer: ecorepsFooterContent
  }
};
