import { DOCUMENT } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from "@angular/core";
import BasicRxComponent from "@components/BasicRxComponent";
import { IDialog } from "@components/modals/modal.types";
import { ModalsService } from "@components/modals/modals.service";
import { filter } from "rxjs/operators";

const OPEN_CLASS_NAME = "open";
type ControlsVariants = "fill" | "outline";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
  encapsulation: ViewEncapsulation.Emulated
})
export class AppDialogComponent
  extends BasicRxComponent
  implements IDialog, OnInit
{
  @Input() closable = true;
  @Input() cancelable = true;
  @Input() submittable = true;
  @Input() submitText = "senden";
  @Input() cancelText = "abbrechen";
  @Input() title = "";
  @Input() show = false;
  @Input() id: string;
  @Input() titleAlign: "center" | "left" = "center";
  @Input() backdropBackground: "fill" | "transparent" = "fill";
  @Input() contentAlign: "top" | "center" = "center";
  @Input() titleClass = "";
  @Input() submitBtnVariant: ControlsVariants = "fill";
  @Input() cancelBtnVariant: ControlsVariants = "outline";
  @Input() closeOnSubmit = true;
  @Input() closeOnCancel = true;

  @Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private el: ElementRef,
    private modalService: ModalsService
  ) {
    super();
  }

  public getBtnClass(variant: ControlsVariants) {
    return `btn accent ${variant === "outline" ? "outline" : ""}`;
  }

  public get titleClassName() {
    return `title ${this.titleClass}`;
  }

  public onBackdropClick(): void {
    if (this.closable) {
      this.onCloseClick();
    }
  }



  public onCancelClick() {
    this.onCancel.emit();
    this.closeOnCancel && this.close();
  }

  public onCloseClick(): void {
    this.onClose.emit();
    this.close();
  }

  public get rootElement(): HTMLDivElement {
    return this.el.nativeElement
  }

  public open(): void {
    this.el.nativeElement.classList.add(OPEN_CLASS_NAME);
  }

  public accept(): void {
    if (this.submittable) {
      this.onSubmit.emit();
      this.closeOnSubmit && this.close();
    }
  }

  public close(): void {
    // Call modal service to handle modal close
    this.modalService.close(this.id);
  }

  public remove() {
    this.document
      .querySelector("#modalContainer")
      .removeChild(this.el.nativeElement);
  }

  public ngOnInit(): void {
    // ensure id attribute exists
    const isModalIdValid = Object.values(this.modalService.MODAL_IDS).includes(
      this.id
    );
    if (!isModalIdValid) {
      console.error("modal must have an id");
      return;
    }

    if(this.modalService.isModalAlreadyExists(this.id)) {
      this.modalService.remove(this.id)
    }
    // move element to modalContainer  so it can be displayed above everything else
    this.document
      .querySelector("#modalContainer")
      .appendChild(this.el.nativeElement);
    // add this modal instance to the modal service so it's accessible from controllers
    this.modalService.add(this);

    const onCloseSubscription = this.modalService.onClose$
      .pipe(filter((id) => id === this.id))
      .subscribe(() => {
        this.el.nativeElement.classList.remove(OPEN_CLASS_NAME);
      });

    this.bag.add(onCloseSubscription);
  }

  public ngOnDestroy(): void {
    this.modalService.remove(this.id);
  }
}
