import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { createCoreUrl } from "@utils/urlFactory";
import { HttpService } from "@core/http";

@Injectable({
  providedIn: "root"
})
export class IntroTutorialService {
  constructor(private http: HttpService<any>) {}

  getTutorial(tutorialId: number): Observable<any> {
    return this.http.get(
      createCoreUrl("content", "intro", tutorialId.toString())
    );
  }

  getTutorialPage(tutorialPageId: number): Observable<any> {
    return this.http.get(
      createCoreUrl("content", "tutorial_page", tutorialPageId.toString())
    );
  }
}
