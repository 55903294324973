import { NgModule, Pipe, PipeTransform } from "@angular/core";
import { CommonModule } from "@angular/common";

@Pipe({
  name: "removeWhiteSpaces"
})
export class RemoveWhitespacesPipe implements PipeTransform {
  transform(value: string): string {
    if (value === null) {
      return null;
    }
    return value.replace(/ /g, "");
  }
}

@NgModule({
  declarations: [RemoveWhitespacesPipe],
  providers: [RemoveWhitespacesPipe],
  imports: [CommonModule],
  exports: [RemoveWhitespacesPipe]
})
export class RemoveWhitespacesPipeModule {}
