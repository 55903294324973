import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter, map, share, tap } from "rxjs/operators";

import {
  IColumn,
  ICourse,
  ICourseEnrolmentInput,
  ICourseEnrolmentResponse,
  IUpdateCourseModel
} from "@models/course";

import { assignFullNames } from "@utils/chapterUtils";
import { IUserAccessState } from "@models/user-access-state";
import IProfile from "@models/profile";

import { HttpService } from "@core/http";
import "rxjs/add/operator/catch";
import {createContentUrl, createCoreUrl, createProfileUrl} from "@utils/urlFactory";
import { EnrollmentService } from "./enrollment.service";

@Injectable({
  providedIn: "root"
})
export class CourseService {
  public currentCourse$: BehaviorSubject<ICourse | null> =
    new BehaviorSubject<ICourse | null>(null);

  constructor(
    private client: HttpService<ICourse>,
    private enrolmentService: EnrollmentService
  ) {}

  public bookmarkCourse(courseId: string): Observable<IProfile> {
    return this.client.post<IProfile>(
      createProfileUrl("courses", courseId),
      null
    );
  }

  public removeBookmark(courseId: string): Observable<IProfile> {
    return this.client.delete<IProfile>(createProfileUrl("courses", courseId));
  }

  public bookmarkedCourses = (): Observable<ICourse[]> => {
    return this.client.get<ICourse[]>(createProfileUrl("courses"));
  };

  public getAccessExportForCourse = (
    courseId: string
  ): Observable<IUserAccessState[]> => {
    return this.client
      .get<any>(createProfileUrl("courses", "access", courseId.toString()))
      .pipe(
        map((exportedFile) => {
          return exportedFile;
        })
      )
      .pipe(share());
  };

  public getAccessListForCourse = (
    courseId: string
  ): Observable<IUserAccessState[]> => {
    return this.client
      .get<IUserAccessState[]>(
        createProfileUrl("courses", "access", courseId.toString())
      )
      .pipe(
        map((list) => {
          return list;
        })
      )
      .pipe(share());
  };

  public removeAllBookmarksFromCourse(course: ICourse) {
    return this.client
      .delete<IUserAccessState[]>(
        createProfileUrl("courses", "bookmarks", course._id.toString())
      )
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(share());
  }

  public revokeAccessForEveryoneInCourse(course: ICourse) {
    return this.client
      .delete<{ updated: IUserAccessState[] }>(
        createProfileUrl("courses", "access", course._id.toString())
      )
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(share());
  }

  public applyPermissionChanges(
    states: IUserAccessState[],
    course: ICourse
  ): Observable<any> {
    return this.client
      .post<IUserAccessState[]>(
        createProfileUrl("courses", "access", course._id.toString()),
        states
      )
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(share());
  }

  public grantCourseToUserId(userId: string, course: ICourse): Observable<any> {
    return this.client
      .post<{ updated: IUserAccessState[] }>(
        createProfileUrl("courses", "access", course._id.toString(), userId),
        {
          headers: {
            //"If-Modified-Since": date
          }
        }
      )
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(share());
  }

  public revokeCourseFromUserId(
    userId: string,
    course: ICourse
  ): Observable<any> {
    return this.client
      .get<IUserAccessState[]>(
        createCoreUrl("courses", course.url_slug, "revoke", userId)
      )
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(share());
  }

  public getCourseById = (
    courseId: string,
    forceReload: boolean = false
  ): Observable<ICourse> => {
    const cachedCourse = this.client.getCachedEntity(courseId);

    if (cachedCourse && !forceReload) {
      return of(cachedCourse);
    } else {
      const url = createCoreUrl("courses", courseId);
      return this.client.get<ICourse>(url).pipe(
        filter((course: ICourse | undefined) => !!course),
        map((course: ICourse) => {
          for (const col of course.columns) {
            col.chapters = assignFullNames(col.chapters, null, course.columns);
          }
          this.client.addToCache(course);
          return course;
        })
      );
    }
  };

  public updateCourse(course: ICourse) {
    const body: IUpdateCourseModel = {
      _id: course._id,
      title: course.title,
      contact_mail: course.contact_mail,
      contact_phone: course.contact_phone,
      coverURL: undefined
    };
    return this.client.put(createCoreUrl("courses"), body).pipe(
      tap(() => {
        this.client.clearCache();
      })
    );
  }

  public rebuildCourse(course: ICourse): Observable<ICourse> {
    return this.client.get<ICourse>(
      createCoreUrl("courses", course.url_slug, "rebuild")
    );
  }

  public deleteCourse(course: ICourse): Observable<string> {
    return this.client.delete<string>(createCoreUrl("courses", course._id));
  }

  public uploadExamResultProofs(file: any, courseId: string): Observable<any> {
    return this.client.post<any>(createProfileUrl("upload", courseId), file, {
      headers: {
          "Access-Control-Allow-Origin": createProfileUrl(),
        }
    });
  }

  public changePreviewModeForAllChapters(courseId: string, columnId: string, previewMode: string): Observable<void> {
    return this.client.post(
      createCoreUrl('courses', courseId, 'column', columnId, 'preview-mode', previewMode),
      {}
    )
  }

  public exportColumnToLatex = (column: IColumn, courseId: string): Observable<Blob> => {
    return this.client.post(
      createContentUrl("courses", courseId, "columns", column.id, "export"),
      {},
      {
        responseType: "blob" as "json",
      }
    );
  }
}
