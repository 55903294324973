import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, share } from "rxjs/operators";
import IOrder, { IOrderPayload, ISummaryRequest } from "@models/order";
import { IOrderRequest } from "@models/order";
import { createCoreUrl, createProfileUrl } from "@utils/urlFactory";
import { HttpService } from "@core/http";

@Injectable({
  providedIn: "root"
})
export class OrdersService {
  constructor(private client: HttpService<IOrder>) {}

  public checkoutState = (body: ISummaryRequest): Observable<any> => {
    return this.client.post<any>(createProfileUrl("order", "summary"), body);
  };

  public orderCourse = (
    courseId: string,
    orderRequest: IOrderRequest
  ): Observable<IOrder> => {
    return this.client
      .post<IOrder>(createCoreUrl("courses", courseId, "order"), orderRequest)
      .pipe(share());
  };

  public orderByBilling = (
    orderRequest: IOrderPayload
  ): Observable<IOrder> => {
    return this.client
      .post<IOrder>(createProfileUrl("order", "billing"), orderRequest)
      .pipe(
        share(),
        catchError((error) => {
          return throwError(error);
        })
      );
  };

  public getOrdersExportForDateRange = (
    dateStart: string,
    dateEnd: string
  ): Observable<IOrder[]> => {
    return (
      this.client
        .get<IOrder[]>(
          createProfileUrl("order", "list", "csv", dateStart, dateEnd)
        )
        .pipe(
          share(),
          catchError((error) => {
            return throwError(error);
          })
        )
    );
  };

  public confirmPaypalTransaction = (
    transactionId: string
  ): Observable<IOrder> => {
    return this.client
      .get<IOrder>(
        createProfileUrl("order", "paypal", "capture", transactionId)
      )
      .pipe(share());
  };

  public createPaypalTransaction = (
    payload: IOrderPayload
  ): Promise<IOrder> => {
    return new Promise((resolve, reject) => {
      this.client
        .post<IOrder>(createProfileUrl("order", "paypal"), payload)
        .subscribe(
          (t) => {
            resolve(t);
          },
          (e) => reject(e)
        );
    });
  };
}
