interface Environment {
  mailgun_domain: string;
  reCaptchaKey: string;
  feedbackAddress: string;
  mailgun_api_key: string;
  videoUploadUrl: string;
  imageUploadUrl: string;
  trackingID: string;
  cmsMediaURL: string;
  auth: {
    clientID: string;
    domain: string;
    responseType: string;
    scope: string;
  };
  paypal: {
    clientId: string;
  };
  features: {
      latexEditor: boolean;
      contentService: boolean;
      magicBox: boolean;
  };
  API_INGESTION: string | null,
  API_NOTIFICATION: string | null,
  API_CONTENT: string | null,
}

export interface FullEnvironment extends Environment {
  production: boolean;
  API_URL: string;
  API_URL_PROFILE: string | null;
  baseUrl: string;
  logoutUrl: string;
  loginCallback: string;
  landingPageUrl: string;
  auth0: {
    clientID: string;
    domain: string;
    responseType: string;
    scope: string;
    redirectUri: string;
  };
  paypal: {
    clientId: string;
  };
  paypalKKL: {
    clientId: string;
  };
}
const API_INGESTION = "https://devapi2.ecoreps.huck-it.de/v2";
const API_NOTIFICATION = "https://devapi2.ecoreps.huck-it.de/v2";
const API_CONTENT = "https://devapi2.ecoreps.huck-it.de/v2";
export const env: Environment = {
  mailgun_domain: "mg.huck-it.de",
  reCaptchaKey: "6LcREL8UAAAAAIY1t_D5GE20U95IJ9ZiBVkbNrM8",
  feedbackAddress:
    "incoming+huck-it/ecoreps/poc+ekrre5o901xp3nlprnvof0yjk@incoming.gitlab.com",
  mailgun_api_key: "5466f6abcd30b0dc7005e2fd0b7c1ce4-a3d67641-dcacf75a",
  videoUploadUrl: "https://api.cloudinary.com/v1_1/ecoreps/video/upload",
  imageUploadUrl: "https://api.cloudinary.com/v1_1/ecoreps/image/upload",
  trackingID: "UA-159355052-2",
  cmsMediaURL: "https://dev.content.ecoreps.huck-it.de/assets",
  auth: {
    clientID: "QC4OfUj8kORjje7sNeaH5EQPceECJa4F",
    domain: "ecoreps-dev.eu.auth0.com",
    responseType: "token id_token",
    scope:
      "openid user_id email profile fullname picture user_metadata app_metadata https://ecoreps.de/roles https://ecoreps.de/purchased_courses expires_in offline_access"
  },
  paypal: {
    clientId:
      "AT1He4K8bkRnBMvzOMNskS5QVPVjgyrXXI4Hh9iRItTR_lhStqgwnw3hqBxKEA8YFB5cqMuut65VdQ9K"
  },
  features: {
    latexEditor: false,
    contentService: false,
    magicBox: false,
  },
  API_INGESTION,
  API_NOTIFICATION,
  API_CONTENT,
};
