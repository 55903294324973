import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { LoginService } from "./login.service";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthGuardService  {
  constructor(public auth: LoginService, public router: Router) {}

  canActivate(): boolean {
    if (!this.auth.loggedInValue) {
      window.open(environment.landingPageUrl, "_self");
      return false;
    }

    return true;
  }
}

@Injectable({
  providedIn: "root"
})
export class AnonymouGuardService  {
  constructor(public auth: LoginService, public router: Router) {}

  async canActivate(): Promise<boolean> {
    try {
      if (
        !this.auth.isLoggedIn.getValue() &&
        !this.auth.isAnonymous.getValue() &&
        !this.auth.idToken
      ) {
        await this.auth.getAnonymous();
      }
      return true;
    } catch (e) {
      console.error(e);
    }
  }
}

@Injectable({
  providedIn: "root"
})
export class OrderGuardService  {
  constructor(public auth: LoginService, public router: Router) {}

  async canActivate(): Promise<boolean> {
    await this.auth.waitForServiceToBeReady();
    if (!this.auth.loggedInValue) {
      this.auth.login();
    }

    if (this.auth.isAnonymous.getValue()) {
      this.auth.login(null, null, true);
    }

    return true;
  }
}

@Injectable({
  providedIn: "root"
})
export class AdminGuardService  {
  constructor(public auth: LoginService, public router: Router) {}

  async canActivate(): Promise<boolean> {
    await this.auth.waitForServiceToBeReady();
    if (!this.auth.loggedInValue) {
      this.router.navigate(["login"]);
      return false;
    }
    if (!this.auth.currentIsAdmin) {
      return false;
    }
    return true;
  }
}
