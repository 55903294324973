import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProgressComponent } from "./components/progress/progress.component";
import { ProgressControlsComponent } from "./components/progress-controls/progress-controls.component";

@NgModule({
  declarations: [ProgressComponent, ProgressControlsComponent],
  imports: [CommonModule],
  exports: [ProgressComponent, ProgressControlsComponent]
})
export class ProgressModule {}
