import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RenderMathPipeModule } from "app/shared/pipes/renderMath";

import { SectionComponent } from "./components/section/section.component";
import { CommentsComponent } from "./components/comments/comments.component";
import { CommentComponent } from "./components/comment/comment.component";

import { AccordionModule } from "@components/accordion/accordion.module";
import { VideoPlayerModule } from "@components/video-player/video-player.module";

import { ModalsModule } from "@components/modals/modals.module";
import { SanitizeHTMLPipeModule } from '@pipe/sanitizeHTML.pipe';
import { CommentTextComponent } from './components/comment-text/comment-text.component';
import { ProgressModule } from "@components/progress/progress.module";

@NgModule({
  declarations: [
    SectionComponent,
    CommentsComponent,
    CommentComponent,
    CommentTextComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    VideoPlayerModule,
    RenderMathPipeModule,
    ProgressModule,
    AccordionModule,
    ModalsModule.forChild(),
    SanitizeHTMLPipeModule,
  ],
  exports: [
    SectionComponent,
    CommentsComponent,
    CommentComponent,
  ]
})
export class SectionModule {}
