import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";

import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    const clientVersion = process.env.NG_APP_VERSION || "0.0.0";

    let clonedRequest: HttpRequest<any>
    // allows to make requests to cloudinary without dropzone and Angular HTTP Module
    if(req.url !== 'https://status.ecoreps.de' && !req.url.startsWith("https://api.cloudinary.com")) {
      clonedRequest = req.clone({
        headers: req.headers.append("clientVersion", clientVersion)
      });
    } else {
      clonedRequest = req
    }

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }
}

export const AppVersionInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AppVersionInterceptor,
  multi: true
};
