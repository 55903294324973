/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";

interface Console {
  log(message?: any, ...optionalParams: any[]): void;
  error(message?: any, ...optionalParams: any[]): void;
  warn(message?: any, ...optionalParams: any[]): void;
}

@Injectable({
  providedIn: "root"
})
export class LoggerService implements Console {
  log(message?: any, ...optionalParams: any[]): void {
    if (environment.production) {
      return;
    }
    console.log(message, ...optionalParams);
  }

  error(message?: any, ...optionalParams: any[]): void {
    console.error(message, ...optionalParams);
  }

  warn(message?: any, ...optionalParams: any[]): void {
    console.warn(message, ...optionalParams);
  }
}
