import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import {AvatarModule} from '@components/avatar/avatar.module';
import { TopbarComponent } from "./topbar.component";
import {NgClickOutsideDirective} from 'ng-click-outside2';

import { ProfileDropdownComponent } from './components/profile-dropdown/profile-dropdown.component'

@NgModule({
  declarations: [TopbarComponent, ProfileDropdownComponent],
  exports: [TopbarComponent],
  imports: [CommonModule, RouterModule, AvatarModule, NgClickOutsideDirective]
})
export class TopbarModule {}
