import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap, share } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import moment from "moment";
import { HttpService } from "@core/http";
import { createCoreUrl } from "@utils/urlFactory";

interface Cache {
  [key: string]: {
    all: [any];
    one: { [key: string]: any };
  };
}

@Injectable({
  providedIn: "root"
})
export class ContentService {
  caches: Cache | object = {};

  constructor(
    private cachedClient: HttpService<any>
  ) {
  }

  public getAll = <T>(): Observable<T[]> => {
    return of([]);
  };

  public getOneCached(collection: string, id: string): any {
    if (!this.caches[collection]) {
      return null;
    }
    if (!this.caches[collection]["one"]) {
      return null;
    }
    if (!this.caches[collection]["one"][id]) {
      return null;
    }
    return this.caches[collection]["one"][id];
  }

  public setOneCached(collection: string, id: string, content: any): void {
    if (!this.caches[collection]) {
      return null;
    }
    this.caches[collection] = {};
    if (!this.caches[collection]["one"]) {
      return null;
    }
    this.caches[collection]["one"] = {};
    this.caches[collection]["one"][id] = content;
  }

  public setAllCached(collection: string, content: any[]): void {
    if (!this.caches[collection]) {
      return null;
    }
    this.caches[collection] = {};
    if (!this.caches[collection]["all"]) {
      return null;
    }
    this.caches[collection]["all"] = content;
  }

  public getAllCached<T>(collection: string): T[] {
    if (!this.caches[collection]) {
      return null;
    }
    if (!this.caches[collection]["all"]) {
      return null;
    }
    return this.caches[collection]["all"];
  }

  public getContentById = <T>(
    collection: string,
    id: string
  ): Observable<T> => {
    const cachedId = this.getCacheId(collection, id);
    const cached = this.cachedClient.getCachedEntity(cachedId)

    if (cached) {
      return of(cached);
    } else {
      return this.cachedClient
        .get<T>(createCoreUrl("content", collection, id))
        .pipe(
          tap((content) => {
            this.cachedClient.addToCache(content, cachedId)
            return content
          }),
          share()
        );
    }
  };

  public getAllContents = <T>(collection: string): Observable<T[]> => {
    const cached = this.getAllCached<T>(collection);
    if (cached != null) {
      return of(cached);
    } else {
      return this.cachedClient
        .get<T[]>(createCoreUrl("content", collection))
        .pipe(
          tap((content) => {
            this.setAllCached(collection, content);
            return this.getAllCached(collection);
          }),
          share()
        );
    }
  };

  private getCacheId(collection, id) {
    return `${collection}_${id}`;
  }
}
