export function isValidObjectId(input: string): boolean {
  let regex = new RegExp(/^[a-fA-F0-9]{24}$/);
  return regex.test(input);
}

export function isNumericId(input: string): boolean {
  let numericRepresentation = Number(input);
  return !isNaN(numericRepresentation) && numericRepresentation % 1 == 0;
}

export function courseByIdLOrSlugQuery(
  input: string,
  includeDeleted = false
): any {
  let deletedQuery = includeDeleted
    ? {}
    : { $or: [{ deleted: false }, { deleted: { $exists: false } }] };

  if (isNumericId(input)) {
    return { ...deletedQuery, externalId: input };
  } else if (isValidObjectId(input)) {
    return { ...deletedQuery, _id: input };
  } else {
    return { ...deletedQuery, url_slug: input };
  }
}
