import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { PortalComponent } from "./components/portal/portal.component";
import {AppDialogComponent} from './components/dialog/dialog.component'
import { ModalsService } from "./modals.service";

@NgModule({
  declarations: [PortalComponent, AppDialogComponent],
  imports: [CommonModule, RouterModule],
  exports: [PortalComponent, AppDialogComponent]
})
export class ModalsModule {
  static forRoot(): ModuleWithProviders<ModalsModule> {
    return {
      ngModule: ModalsModule,
      providers: [
        {provide: ModalsService, useClass: ModalsService }
      ],
    };
  }

  static forChild(): ModuleWithProviders<ModalsModule> {
    return {
      ngModule: ModalsModule
    };
  }
}
